import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewProject from "../../components/MasterFiles/NewProject";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { SelectButton } from "primereact/selectbutton";
import { ProgressBar } from "primereact/progressbar";
import AssignProjectManager from "../../components/MasterFiles/AssignProjectManager";
import { UPLOADS_URL } from "../../config/api";
import { AvatarGroup } from "primereact/avatargroup";
import { Avatar } from "primereact/avatar";
import ViewAssignees from "../../components/MasterFiles/ViewAssignees";
import { useNavigate } from "react-router-dom";
import { useThemeProvider } from "../../providers/useThemeProvider";

function AllProjects() {
  const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showAssignProjectManagerModal, setShowAssignProjectManagerModal] =
    useState(false);
  const [projects, setProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [view, setView] = useState("list");

  // View Assignees
  const [assingeeDialogHeader, setAssigneeDialogHeader] = useState("");
  const [viewAssigneeDialogVisible, setViewAssigneesDialogVisible] =
    useState(false);
  const [viewAssigneeDialogAssignees, setViewAssigneeDialogAssignees] =
    useState([]);

  const viewTemplate = (option) => {
    return <i className={option.icon}></i>;
  };

  const views = [
    { name: "List", value: "list", icon: "pi pi-list" },
    { name: "Table", value: "table", icon: "pi pi-table" },
  ];

  const toggleNewProject = () => {
    setShowNewProjectModal(!showNewProjectModal);
  };

  const toggleAssignProjectManagerModal = () => {
    setShowAssignProjectManagerModal(!showAssignProjectManagerModal);
  };

  const showError = (error) => {
    const status = error?.response?.status;
    if (status != 404) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getProjects = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        setProjects(response.data);
      }
    } catch (error) {
      showError(error);
      setProjects([]);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      const response = await protectedAPI.delete("project/delete/" + projectId);
      if (response.status === 200) {
        getProjects();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showError(error);
    }
  };

  const deleteProjectConfirm = (projectId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this project?",
      onAccept: () => deleteProject(projectId),
    });
  };

  const openEditModal = (project) => {
    setSelectedProject(project);
    setIsUpdate(true);
    toggleNewProject();
  };

  const openAssignProjectModal = (project) => {
    setSelectedProject(project);
    toggleAssignProjectManagerModal();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const titleTemplate = (project) => {
    return (
      <>
        <div className="d-flex flex-column">
          <small className="fw-bold">{project.title}</small>
          <small
            className="badge text-bg border border-dark-subtle fw-bold"
            style={{ width: "fit-content" }}
          >
            {project.code}
          </small>
        </div>
      </>
    );
  };

  const progressTemplate = (project) => {
    return (
      <>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between mb-2">
            <small>{parseFloat(project.progress?.progress || 0).toFixed(0)} %</small>
          </div>
          <ProgressBar
            value={project.progress?.progress || 0}
            style={{ height: "5px" }}
            showValue={false}
          ></ProgressBar>
        </div>
      </>
    );
  };

  const descriptionTemplate = (project) => {
    return (
      <>
        <p className="mt-3 text-muted" style={{ fontSize: "12px" }}>
          {project.description?.length > 100
            ? project.description.substring(0, 100) + "..."
            : project.description}
        </p>
      </>
    );
  };

  const projectManagerTemplate = (project) => {
    return (
      <>
        <div className="d-flex mb-2">
          <small>
            {project?.projectManagers &&
            project?.projectManagers?.length > 0 ? (
              <AvatarGroup>
                {project.projectManagers.map(
                  (manager, key) =>
                    key < 2 && (
                      <Avatar
                        key={key}
                        image={
                          UPLOADS_URL +
                          "employees/" +
                          (manager.userDetails.image || "no-user.png")
                        }
                        size="small"
                        shape="circle"
                        onClick={() => {
                          setAssigneeDialogHeader(`Assigned Project Managers`);
                          setViewAssigneeDialogAssignees(
                            project.projectManagers
                          );
                          setViewAssigneesDialogVisible(true);
                        }}
                      />
                    )
                )}
                {project.projectManagers.length > 2 && (
                  <Avatar
                    label={`+${project.projectManagers.length - 2}`}
                    shape="circle"
                    size="small"
                  />
                )}
              </AvatarGroup>
            ) : project?.userGroup === "Super Admin" ||
              project?.userGroup === "Director" ? (
              <a
                href="#"
                className="link-secondary"
                onClick={() => {
                  openAssignProjectModal(project);
                }}
                style={{ fontSize: "12px" }}
              >
                <i className="pi pi-plus me-2"></i>
                Assign Project Manager
              </a>
            ) : (
              <i>No people assigned.</i>
            )}
          </small>
        </div>
      </>
    );
  };

  const modulesTestCasesTemplate = (project) => {
    return (
      <>
        <div className="d-flex">
          <span className="badge bg-warning-subtle text-warning-emphasis border border-warning-subtle fw-normal me-2">
            {project.modulesCount} Modules
          </span>
          <span className="badge bg-info-subtle text-info-emphasis border border-info-subtle fw-normal">
            {project.testCasesCount} Test Cases
          </span>
        </div>
      </>
    );
  };

  const actionTemplate = (project) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button
            className="btn btn-sm bg-transparent border"
            onClick={() => navigate(`/projects/view/${project.id}`)}
          >
            <i className="pi pi-eye"></i>
          </button>
          {(project?.userGroup === "Super Admin" ||
            project?.userGroup === "Director") && (
            <>
              <button
                className="btn btn-sm bg-transparent border"
                onClick={() => {
                  openAssignProjectModal(project);
                }}
              >
                <i className="pi pi-users"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm bg-transparent border"
                onClick={() => openEditModal(project)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm bg-transparent border text-danger"
                onClick={() => deleteProjectConfirm(project.id)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </>
          )}
        </div>
      </>
    );
  };

  const projectTableData = {
    fetchData: getProjects,
    records: projects,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Code", field: "code" },
      { header: "Title", field: "title" },
      { header: "Description", field: "description" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      {
        header: "_Code",
        field: "code",
        sortable: true,
        style: { display: "none" },
      },
      {
        header: "_Title",
        field: "title",
        sortable: true,
        style: { display: "none" },
      },
      { header: "Title", body: titleTemplate },
      { header: "Description", body: descriptionTemplate },
      { header: "Project Managers", body: projectManagerTemplate },
      { header: "Modules", body: modulesTestCasesTemplate },
      { header: "Progress", body: progressTemplate },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["code", "title"],
    dataKey: "id",
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Projects" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            <div>
              {user?.groupId == 2 || user?.groupId == 3 ? (
                <Button
                  variant="info"
                  onClick={() => {
                    setSelectedProject(null);
                    setIsUpdate(false);
                    toggleNewProject();
                  }}
                >
                  <i className="pi pi-plus me-1"></i> Create New Project
                </Button>
              ) : null}
            </div>
            <div>
              <SelectButton
                value={view}
                onChange={(e) => setView(e.value)}
                itemTemplate={viewTemplate}
                optionLabel="name"
                options={views}
              />
            </div>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              view === "table" ? (
                <Card className="border">
                  <CustomDataTable
                    records={projectTableData.records}
                    fetchData={projectTableData.fetchData}
                    exportColumns={projectTableData.exportColumns}
                    tableColumns={projectTableData.tableColumns}
                    globalFilterFields={projectTableData.globalFilterFields}
                    dataKey={projectTableData.dataKey}
                  />
                </Card>
              ) : (
                <>
                  <div className="container-fluid px-0">
                    <div className="row mb-3">
                      <strong>
                        We found {projects.length} project
                        {projects.length > 1 && "s"} .
                      </strong>
                    </div>
                    <div className="row">
                      {projects.map((project) => (
                        <div className="col-12 col-sm-4 mb-4" key={project.id}>
                          <Card
                            key={project.id}
                            className="project-card dark-gradient-bg border h-100"
                            style={{ minHeight: "200px" }}
                          >
                            <h5 className="fw-bold">{project.title}</h5>
                            <small className="badge text-bg border border-dark-subtle fw-bold">
                              {project.code}
                            </small>
                            {descriptionTemplate(project)}
                            {projectManagerTemplate(project)}
                            {modulesTestCasesTemplate(project)}
                            <div className="mt-4">
                              {progressTemplate(project)}
                            </div>
                            <button
                              className="btn btn-sm border border-dark-subtle mt-3"
                              onClick={() =>
                                navigate(`/projects/view/${project.id}`)
                              }
                            >
                              View Project
                            </button>
                            <div className="d-flex justify-content-between">
                              <div></div>
                              {(project?.userGroup === "Super Admin" ||
                                project?.userGroup === "Director") && (
                                <div className="mt-4">
                                  <button
                                    className="btn btn-sm bg-transparent border me-1"
                                    onClick={() => {
                                      openAssignProjectModal(project);
                                    }}
                                  >
                                    <i className="pi pi-users"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm bg-transparent border me-1"
                                    onClick={() => {
                                      openEditModal(project);
                                    }}
                                  >
                                    <i className="pi pi-pencil"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm bg-transparent border text-danger"
                                    onClick={() =>
                                      deleteProjectConfirm(project.id)
                                    }
                                  >
                                    <i className="pi pi-trash"></i>
                                  </button>
                                </div>
                              )}
                            </div>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewProject
        show={showNewProjectModal}
        toggle={toggleNewProject}
        getProjects={getProjects}
        isUpdate={isUpdate}
        selectedProject={selectedProject}
      />
      <AssignProjectManager
        show={showAssignProjectManagerModal}
        toggle={toggleAssignProjectManagerModal}
        reloadProjects={getProjects}
        _selectedProject={selectedProject}
      />
      <ViewAssignees
        header={assingeeDialogHeader}
        viewAssgineeDialogVisible={viewAssigneeDialogVisible}
        setViewAssigneesDialogVisible={setViewAssigneesDialogVisible}
        assignees={viewAssigneeDialogAssignees}
      />
    </div>
  );
}

export default AllProjects;
