import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import protectedAPI from "../../config/protectedAPI";
import { ProgressSpinner } from "primereact/progressspinner";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { Dropdown } from "primereact/dropdown";
import SEVERITY_OPTIONS from "../../config/severityOptions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../css/testcaseTable.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import TestCaseSidePanel from "../../components/MasterFiles/TestCaseSidePanel";
import { useThemeProvider } from "../../providers/useThemeProvider";
import { exportTestcasesToExcel, exportTestcasesToWord } from "./Exports";
import { Checkbox } from "primereact/checkbox";
import RequestApprovalModal from "../../components/MasterFiles/CreateRequestApprovalModal";
import HistoryModal from "../../components/MasterFiles/HistoryModal";

function AllTestCases() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();
  const { appTheme } = useThemeProvider();

  const [user, setUser] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewTestCaseModal, setShowNewTestCaseModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [modules, setModules] = useState(null);
  const [riskCategories, setRiskCategories] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [filterProjects, setFilterProjects] = useState([]);
  const [filterModules, setFilterModules] = useState([]);
  const [testcases, setTestCases] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [rowSelectionMode, setRowSelectionMode] = useState("single");

  const [checkedTestcases, setCheckedTestcases] = useState([]);

  const severityOptions = SEVERITY_OPTIONS;

  const [selectedFilterProject, setSelectedFilterProject] = useState({
    id: 0,
    title: "All Projects",
  });
  const [selectedFilterModule, setSelectedFilterModule] = useState({
    id: 0,
    title: "All Modules",
  });
  const [selectedFilterSeverity, setSelectedFilterSeverity] = useState(0);

  const [sidePanelVisible, setSidePanelVisible] = useState(false);
  const [selectedTestCase, setSelectedTestCase] = useState(null);

  const [approvalRequestType, setApprovalRequestType] = useState(1);
  const [visibleRequestApprovalModal, setVisibleRequestApprovalModal] =
    useState(false);
  const toggleRequestApprovalModalVisibility = () => {
    setVisibleRequestApprovalModal(!visibleRequestApprovalModal);
  };

  const toggleNewTestCase = () => {
    setShowNewTestCaseModal(!showNewTestCaseModal);
  };

  const toggleSidePanelVisibility = (testcase) => {
    setSelectedTestCase(testcase);
    setSidePanelVisible(!sidePanelVisible);
  };

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const toggleHistoryModal = (testcase) => {
    setSelectedTestCase(testcase);
    setShowHistoryModal(!showHistoryModal);
  };

  const showError = (error) => {
    const status = error?.response?.status;
    if (status != 404) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getProjects = async () => {
    try {
      let response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        response.data = response.data.filter(
          (project) => project.modulesCount > 0
        );
        setProjects(response.data);
        setFilterProjects([{ id: 0, title: "All Projects" }, ...response.data]);
      }
    } catch (error) {
      setProjects([]);
      showError(error);
    }
  };

  const getModulesGroupedByProjectId = async () => {
    try {
      const response = await protectedAPI.get(
        "module/getAllModulesGroupedByProjectId"
      );
      if (response.status === 200) {
        setModules(response.data || {});
      }
    } catch (error) {
      setModules({});
      showError(error);
    }
  };

  const getRiskCategories = async () => {
    try {
      const response = await protectedAPI.get("riskCategory/all");
      if (response.status === 200) {
        setRiskCategories(response.data);
      }
    } catch (error) {
      setRiskCategories([]);
      showError(error);
    }
  };

  const getNewEmptyRow = () => {
    return {
      id: 0,
      projectId: null,
      projectTitle: null,
      moduleId: null,
      moduleTitle: null,
      riskCategoryId: null,
      riskCategoryTitle: null,
      ref: null,
      possibleRisk: null,
      severity: null,
      observations: null,
      implications: null,
      recommendations: null,
    };
  };

  const getTestCases = async (
    _selectedModule,
    _selectedSeverity,
    _selectedProject
  ) => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get(
        "testcase/all?moduleId=" +
          (_selectedModule?.id || 0) +
          "&severity=" +
          (_selectedSeverity || 0) +
          "&projectId=" +
          (_selectedProject?.id || 0)
      );
      if (response.status === 200) {
        response.data.push(getNewEmptyRow());
        setTestCases(response.data);
      }
    } catch (error) {
      let emptyTestCase = [];
      emptyTestCase.push(getNewEmptyRow());
      setTestCases(emptyTestCase);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTestCase = async (testCase) => {
    try {
      setIsSaving(true);
      const response = await protectedAPI.delete(
        "testcase/delete/" + testCase.id
      );
      if (response.status === 200) {
        const _testCases = [...testcases];
        const index = _testCases.findIndex((tc) => tc.id === testCase.id);
        _testCases.splice(index, 1);
        setTestCases(_testCases);
        showToast({
          type: "success",
          detail: "Test Case deleted successfully.",
        });
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const exportToExcel = async () => {
    showConfirmDialog({
      header: "Export to Excel",
      message: "Are you sure you want to export these testcases to Excel?",
      onAccept: () => {
        if (testcases) {
          showToast({
            type: "info",
            detail: "Exporting to Excel...",
          });
          exportTestcasesToExcel(testcases);
        } else {
          showToast({
            type: "warn",
            detail: "No data to export.",
          });
        }
      },
    });
  };

  const exportToWord = async () => {
    showConfirmDialog({
      header: "Export to Word",
      message: "Are you sure you want to export these testcases to Word?",
      onAccept: () => {
        if (testcases) {
          showToast({
            type: "info",
            detail: "Exporting to Word...",
          });
          exportTestcasesToWord(testcases);
        } else {
          showToast({
            type: "warn",
            detail: "No data to export.",
          });
        }
      },
    });
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const dropdownValueTemplate = (option) => {
    return (
      <span className="text-wrap" style={{ fontSize: "12px" }}>
        {option?.title.length > 30
          ? option?.title.substring(0, 30) + "..."
          : option?.title}
      </span>
    );
  };

  const dropdownItemTemplate = (option) => {
    return (
      <span className="text-wrap" style={{ fontSize: "12px" }}>
        {option?.title}
      </span>
    );
  };

  const severityDropdownTdTemplate = (option) => {
    const _severity = severityOptions.find(
      (status) => status?.value == option.severity
    );
    return (
      <div className="d-flex justify-content-start ps-2">
        <span
          className="fw-medium rounded-pill px-2 shadow"
          style={{
            fontSize: "12px",
            backgroundColor: _severity?.color + "50",
            border: "1px solid " + _severity?.color,
          }}
        >
          {_severity?.label}
        </span>
      </div>
    );
  };

  const severityDropdownItemTemplate = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="rounded-circle p-1 me-2"
          style={{ backgroundColor: option?.color, fontSize: "12px" }}
        ></span>
        {option?.label}
      </div>
    );
  };

  const moreBtnTemplate = (option) => {
    return option?.id > 0 ? (
      <div className="d-flex flex-column align-items-center p-1">
        <button
          className="btn btn-sm more-td-btn mb-1"
          onClick={() => toggleSidePanelVisibility(option)}
        >
          <i className="bi bi-three-dots"></i>
        </button>
        <button
          className="btn btn-sm more-td-btn mb-1"
          onClick={() => toggleHistoryModal(option)}
        >
          <i className="pi pi-history"></i>
        </button>
        <div>
          <Checkbox
            onChange={(e) => {
              e.stopPropagation();
              setCheckedTestcases((prev) => {
                const testcaseIdentifier = option;
                const exists = prev.some(
                  (item) =>
                    item.id === option.id && item.projectId === option.projectId
                );
                if (exists) {
                  return prev.filter(
                    (item) =>
                      item.id !== option.id ||
                      item.projectId !== option.projectId
                  );
                } else {
                  return [...prev, testcaseIdentifier];
                }
              });
            }}
            checked={checkedTestcases.some(
              (item) =>
                item.id === option.id && item.projectId === option.projectId
            )}
          ></Checkbox>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const updateAttribute = async (e) => {
    const { rowData, newValue, field, originalEvent: event } = e;
    let valueToSave = newValue;
    let fieldToSave = field;
    const oldValue = rowData[field];
    let oldId = "";
    let oldTitle = "";

    // used only if project update failed
    let oldModuleId = rowData?.moduleId;
    let oldModuleTitle = rowData?.moduleTitle;

    switch (field) {
      case "projectTitle":
        if (
          newValue?.id === rowData?.projectId ||
          !newValue?.id ||
          !newValue?.title
        ) {
          event.preventDefault();
          return;
        }
        valueToSave = newValue?.id;
        fieldToSave = "projectId";
        oldId = rowData.projectId;
        oldTitle = rowData?.projectTitle;
        rowData.projectId = valueToSave;
        rowData.projectTitle = newValue?.title;
        break;
      case "moduleTitle":
        if (
          newValue?.id === rowData.moduleId ||
          !newValue?.id ||
          !newValue?.title
        ) {
          event.preventDefault();
          return;
        }
        valueToSave = newValue?.id;
        fieldToSave = "moduleId";
        oldId = rowData.moduleId;
        oldTitle = rowData.moduleTitle;
        rowData.moduleId = valueToSave;
        rowData.moduleTitle = newValue?.title;
        break;
      case "riskCategoryTitle":
        if (
          newValue?.id === rowData.riskCategoryId ||
          !newValue?.id ||
          !newValue?.title
        ) {
          event.preventDefault();
          return;
        }
        valueToSave = newValue?.id;
        fieldToSave = "riskCategoryId";
        oldId = rowData.riskCategoryId;
        oldTitle = rowData.riskCategoryTitle;
        rowData.riskCategoryId = valueToSave;
        rowData.riskCategoryTitle = newValue?.title;
        break;
      default:
        if (newValue === rowData[field]) {
          event.preventDefault();
          return;
        }
        valueToSave = newValue?.length > 0 ? newValue.trim() : newValue;
        rowData[field] = valueToSave;
        break;
    }

    try {
      setIsSaving(true);
      const response = await protectedAPI.post(
        "testcase/updateTestCaseAttribute/" + rowData.id,
        {
          attribute: fieldToSave,
          value: valueToSave,
        }
      );
      if (response.status !== 200 && response.status !== 201) {
        switch (field) {
          case "projectTitle":
            rowData.projectId = oldId;
            rowData.projectTitle = oldTitle;
            rowData.moduleId = oldModuleId;
            rowData.moduleTitle = oldModuleTitle;
            break;
          case "moduleTitle":
            rowData.moduleId = oldId;
            rowData.moduleTitle = oldTitle;
            break;
          case "riskCategoryTitle":
            rowData.riskCategoryId = oldId;
            rowData.riskCategoryTitle = oldTitle;
            break;
          default:
            rowData[field] = oldValue;
            break;
        }
      }
      if (response.status === 200) {
        const savedTestCase = response.data.testCase;
        rowData.ref = savedTestCase.ref;
        rowData.moduleId = savedTestCase.moduleId;
        rowData.moduleTitle = savedTestCase.moduleTitle;
      }
      if (response.status === 201 && rowData.id === 0) {
        // console.log(response.data);
        const savedTestCase = response.data.testCase;
        let _testCases = [...testcases];
        _testCases = _testCases.map((testCase) =>
          testCase.id === 0 ? savedTestCase : testCase
        );
        _testCases.push(getNewEmptyRow());
        // console.log(_testCases);
        setTestCases(_testCases);
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onCellEditComplete = async (e) => {
    await updateAttribute(e);
  };

  const cellEditor = (options) => {
    switch (options.field) {
      case "projectTitle":
        return projectEditor(options);
      case "moduleTitle":
        return moduleEditor(options);
      case "riskCategoryTitle":
        return riskCategoryEditor(options);
      case "possibleRisk":
        return textAreaEditor(options);
      case "observations":
        return textAreaEditor(options);
      case "implications":
        return textAreaEditor(options);
      case "recommendations":
        return textAreaEditor(options);
      case "severity":
        return statusEditor(options);
      default:
        return textEditor(options);
    }
  };

  const projectEditor = (options) => {
    const selectedProject = projects.find(
      (project) => project?.id === options?.rowData?.projectId
    );
    return (
      <Dropdown
        value={selectedProject}
        options={projects}
        onChange={(e) => options.editorCallback(e.value)}
        itemTemplate={dropdownItemTemplate}
        valueTemplate={dropdownValueTemplate}
        filter
        style={{ width: "100%" }}
        placeholder="Select a Project"
      />
    );
  };

  const moduleEditor = (options) => {
    const projectId = options.rowData?.projectId;
    const _modules = modules ? modules[projectId] : [];
    const selectedModule = _modules?.find(
      (module) => module?.id === options?.rowData?.moduleId
    );
    return (
      <Dropdown
        value={selectedModule}
        options={_modules}
        onChange={(e) => options.editorCallback(e.value)}
        itemTemplate={dropdownItemTemplate}
        valueTemplate={dropdownValueTemplate}
        filter
        style={{ width: "100%" }}
        placeholder="Select a Module"
        disabled={!projectId}
      />
    );
  };

  const riskCategoryEditor = (options) => {
    const selectedRiskCat = riskCategories.find(
      (riskCategory) => riskCategory?.id === options?.rowData?.riskCategoryId
    );
    return (
      <Dropdown
        value={selectedRiskCat}
        options={riskCategories}
        onChange={(e) => options.editorCallback(e.value)}
        itemTemplate={dropdownItemTemplate}
        valueTemplate={dropdownValueTemplate}
        filter
        style={{ width: "100%" }}
        placeholder="Select a Category"
        disabled={!options.rowData?.projectId}
      />
    );
  };

  const statusEditor = (options) => {
    const _severityOptions = severityOptions.slice(1);
    const selectedStatus = severityOptions.find(
      (status) => status.value == options?.value
    );
    return (
      <Dropdown
        value={selectedStatus}
        options={_severityOptions}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Select Severity"
        itemTemplate={severityDropdownItemTemplate}
        optionLabel="label"
        style={{ width: "100px" }}
        disabled={!options.rowData?.projectId}
      />
    );
  };

  const textEditor = (options) => {
    return (
      <InputText
        className="w-100"
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        disabled={!options.rowData?.projectId}
      />
    );
  };

  const textAreaEditor = (options) => {
    return (
      <InputTextarea
        style={{ width: "100%" }}
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        rows={5}
        disabled={!options.rowData?.projectId}
      />
    );
  };

  const textTdTemplate = (option, field) => {
    // console.log("rendering...");
    return (
      <div className="d-flex align-items-center p-2">
        <p className="text-wrap fw-medium h-100" style={{ fontSize: "12px" }}>
          {/* {(option[field] && option[field] != "") ? option[field] : <i>N/A</i>} */}
          {option[field]}
        </p>
      </div>
    );
  };

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);

    getProjects();
    getModulesGroupedByProjectId();
    getRiskCategories();
  }, []);

  useEffect(() => {
    getTestCases(
      selectedFilterModule,
      selectedFilterSeverity,
      selectedFilterProject
    );
  }, [selectedFilterModule, selectedFilterSeverity, selectedFilterProject]);

  useEffect(() => {
    // console.log(checkedTestcases);
  }, [checkedTestcases]);

  const columns = [
    {
      field: "checkbox",
      header: "",
      selectionMode: "multiple",
      style: { width: "50px", textAlign: "center" },
      enabled: false,
    },
    {
      field: "no",
      header: "",
      body: rowNoTemplate,
      style: { width: "40px", textAlign: "center" },
      frozen: true,
    },
    {
      field: "more",
      header: "",
      body: moreBtnTemplate,
      frozen: true,
      style: { width: "20px", textAlign: "center" },
    },
    {
      field: "projectTitle",
      header: "Project",
      body: (option) => textTdTemplate(option, "projectTitle"),
      style: { width: "10%" },
    },
    {
      field: "moduleTitle",
      header: "Module",
      body: (option) => textTdTemplate(option, "moduleTitle"),
      style: { width: "10%" },
    },
    {
      field: "riskCategoryTitle",
      header: "Risk Category",
      body: (option) => textTdTemplate(option, "riskCategoryTitle"),
      style: { width: "10%" },
    },
    {
      field: "ref",
      header: "Test Ref",
      body: (option) => textTdTemplate(option, "ref"),
      style: { width: "100px" },
    },
    {
      field: "possibleRisk",
      header: "Possible Risk",
      body: (option) => textTdTemplate(option, "possibleRisk"),
    },
    {
      field: "severity",
      header: "Severity",
      body: severityDropdownTdTemplate,
      style: { width: "80px" },
    },
    {
      field: "observations",
      header: "Observations",
      body: (option) => textTdTemplate(option, "observations"),
    },
    {
      field: "implications",
      header: "Implications",
      body: (option) => textTdTemplate(option, "implications"),
    },
    {
      field: "recommendations",
      header: "Recommendations",
      body: (option) => textTdTemplate(option, "recommendations"),
    },
  ];

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Test Cases" />
        <div className="d-flex flex-column pt-3">
          <div className="d-flex flex-column px-4">
            <div className="tc-control-panel sticky-top">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <small style={{ fontSize: "12px" }}>
                      <i
                        className="pi pi-filter mb-2"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      Severity:
                    </small>
                    <Dropdown
                      value={selectedFilterSeverity}
                      onChange={(e) => setSelectedFilterSeverity(e.value)}
                      options={severityOptions}
                      itemTemplate={severityDropdownItemTemplate}
                      valueTemplate={severityDropdownItemTemplate}
                      filter
                      optionLabel="label"
                      style={{ width: "120px" }}
                      className="me-1"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <small style={{ fontSize: "12px" }}>
                      <i
                        className="pi pi-filter mb-2"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      Project:
                    </small>
                    <Dropdown
                      value={selectedFilterProject}
                      onChange={(e) => {
                        const selectedProjectModules =
                          modules[e.value.id] || [];
                        setFilterModules([
                          { id: 0, title: "All Modules" },
                          ...selectedProjectModules,
                        ]);
                        setSelectedFilterProject(e.value);
                      }}
                      options={filterProjects}
                      filter
                      optionLabel="title"
                      style={{ width: "150px" }}
                      className="me-1"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <small style={{ fontSize: "12px" }}>
                      <i
                        className="pi pi-filter mb-2"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      Module:
                    </small>
                    <Dropdown
                      value={selectedFilterModule}
                      onChange={(e) => setSelectedFilterModule(e.value)}
                      options={filterModules}
                      filter
                      optionLabel="title"
                      style={{ width: "150px" }}
                      className="me-1"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm border me-1"
                    style={{ height: "fit-content" }}
                    onClick={() => exportToExcel()}
                  >
                    <i className="bi bi-file-earmark-excel-fill me-1"></i>{" "}
                    Export to Excel
                  </button>
                  <button
                    className="btn btn-sm border me-1"
                    style={{ height: "fit-content" }}
                    onClick={() => exportToWord()}
                  >
                    <i className="bi bi-file-earmark-word-fill me-1"></i> Export
                    to Word
                  </button>
                  <button
                    className="btn btn-sm btn-info"
                    style={{ height: "fit-content" }}
                    onClick={() => getTestCases()}
                  >
                    <i className="bi bi-arrow-clockwise me-1"></i>
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="tc-table mt-3" style={{ paddingBottom: "100px" }}>
            {!isLoading ? (
              <DataTable
                value={testcases}
                editMode="cell"
                dataKey="id"
                selectionMode={rowSelectionMode}
                selection={checkedRows}
                onSelectionChange={(e) => setCheckedRows(e.value)}
                tableStyle={{ minWidth: "100vw" }}
                stripedRows
                // scrollable
                columnResizeMode="expand"
                resizableColumns
                showGridlines
                onMouseDownCapture={(e) => {
                  e.stopPropagation();
                }}
              >
                {columns.map(
                  ({
                    field,
                    header,
                    style,
                    selectionMode,
                    body,
                    frozen,
                    enabled,
                  }) => {
                    if (enabled === false) return null;
                    return (
                      <Column
                        key={field}
                        field={field}
                        header={header}
                        selectionMode={field === "checkbox" && selectionMode}
                        style={style}
                        body={body}
                        editor={
                          field !== "no" &&
                          field !== "more" &&
                          field !== "checkbox" &&
                          field !== "ref" &&
                          ((options) => cellEditor(options))
                        }
                        onCellEditComplete={onCellEditComplete}
                        frozen={frozen || false}
                      />
                    );
                  }
                )}
              </DataTable>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="p-2 status-div border-top border-dark-subtle fixed-bottom"
        style={{
          boxShadow:
            "0 -4px 10px " +
            (appTheme === "light"
              ? "rgba(195, 195, 195, 0.5)"
              : "rgba(0, 0, 10, 0.5)"),
        }}
      >
        <div className="d-flex">
          <small className="border-end border-dark-subtle pe-3">
            {isSaving ? (
              <div className="d-flex align-items-center">
                <div
                  className="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <small className="fw-medium ms-2">Saving...</small>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-check-circle-fill"
                  style={{ color: "limegreen" }}
                ></i>
                <small className="fw-medium ms-2">Saved</small>
              </div>
            )}
          </small>
          <small className="border-end border-dark-subtle pe-3 ms-3 fw-medium">
            {testcases?.length > 0 ? testcases?.length - 1 : 0} Test Cases
          </small>
          <small className="border-end border-dark-subtle pe-3 ms-3 fw-medium">
            Selected Testcase :{" "}
            <small className="text-warning-emphasis fw-bold">
              {checkedRows?.ref || `No Ref Found [${checkedRows?.id || "-"}]`}
            </small>
          </small>
          {checkedRows?.id ? (
            <i
              className="bi bi-trash-fill border-end border-dark-subtle pe-3 ms-3 text-secondary link-danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                showConfirmDialog({
                  header: "Delete Test Case",
                  message: "Are you sure you want to delete this Test Case?",
                  onAccept: () => deleteTestCase(checkedRows),
                });
              }}
            ></i>
          ) : null}
          <small className="border-end border-dark-subtle pe-3 ms-3 fw-medium">
            <i className="bi bi-check-square-fill me-1"></i> Checked Testcases :{" "}
            <small className="text-warning-emphasis fw-bold ms-2">
              {checkedTestcases?.length}
            </small>
          </small>
          {/*
              If all checked testcases are from the same project, show the request approval icon 
          */}
          {checkedTestcases?.length > 0 &&
            checkedTestcases.every(
              (testcase, _, array) =>
                testcase.projectId === array[0].projectId &&
                testcase.managerAccepted == 0 &&
                testcase.directorAccepted == 0
            ) &&
            user?.groupId == 14 && (
              <i
                className="bi bi-patch-check-fill border-end border-dark-subtle pe-3 ms-3 text-secondary link-info"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showConfirmDialog({
                    header: "Please Confirm",
                    message:
                      "Are you sure you want to request approval from project managers for " +
                      checkedTestcases?.length +
                      " checked testcase(s)?",
                    onAccept: () => {
                      setSelectedTestCase(checkedTestcases[0]);
                      setApprovalRequestType(1);
                      toggleRequestApprovalModalVisibility();
                    },
                  });
                }}
              ></i>
            )}
        </div>
      </div>
      <TestCaseSidePanel
        visible={sidePanelVisible}
        toggleSidePanelVisibility={() => toggleSidePanelVisibility(null)}
        selectedTestCase={selectedTestCase}
        testcases={testcases}
        setTestCases={setTestCases}
        setSelectedTestCase={setSelectedTestCase}
        projects={projects}
        modules={modules}
        riskCategories={riskCategories}
        severityOptions={severityOptions}
        dropdownItemTemplate={dropdownItemTemplate}
        dropdownValueTemplate={dropdownValueTemplate}
        severityDropdownItemTemplate={severityDropdownItemTemplate}
      />
      <RequestApprovalModal
        visibleRequestApprovalModal={visibleRequestApprovalModal}
        toggleRequestApprovalModalVisibility={
          toggleRequestApprovalModalVisibility
        }
        selectedTestCase={selectedTestCase}
        approvalRequestType={approvalRequestType}
        testcases={testcases}
        setTestCases={setTestCases}
        setSelectedTestCase={setSelectedTestCase}
        checkedTestCases={checkedTestcases}
      />
      <HistoryModal
        showHistoryModal={showHistoryModal}
        setShowHistoryModal={setShowHistoryModal}
        selectedTestcase={selectedTestCase}
      />
    </div>
  );
}

export default AllTestCases;
