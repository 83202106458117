import { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import MenuBar from "../components/Menubar/MenuBar";
import protectedAPI from "../config/protectedAPI";
import { useToastProvider } from "../providers/useToastProvider";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { useNavigate } from "react-router-dom";
import ActiveProjectsSkeleton from "../components/Skeleton/ActiveProjectsSkeleton";

function App() {
  const showToast = useToastProvider();
  const navigate = useNavigate();

  const formatTime = (time) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return new Date(time)
      .toLocaleTimeString([], { ...options, hour12: true })
      .replace(" am", " AM")
      .replace(" pm", " PM");
  };

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const [time, setTime] = useState(formatTime(new Date()));

  const [projects, setProjects] = useState([]);
  const [modules, setModules] = useState([]);
  const [testCases, setTestCases] = useState([]);
  const [progress, setProgress] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);

  const showError = (error) => {
    const status = error?.response?.status;
    if (status != 404) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getProjects = async () => {
    try {
      setProjectLoading(true);
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        setProjects(response.data);
      }
    } catch (error) {
      showError(error);
    } finally {
      setProjectLoading(false);
    }
  };

  const getModules = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("module/all");
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (error) {
      showError(error);
    }
  };

  const getTestCases = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("testcase/all");
      if (response.status === 200) {
        setTestCases(response.data);
        const acceptedTestCases = response.data.filter(
          (testCase) =>
            testCase.managerAccepted == 1 && testCase.directorAccepted == 1
        );
        const progressPercentage =
          (acceptedTestCases.length / response.data.length) * 100;
        setProgress(progressPercentage);
      }
    } catch (error) {
      showError(error);
    }
  };

  const progressTemplate = (project) => {
    return (
      <>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between mb-2">
            <small>
              {parseFloat(project.progress?.progress || 0).toFixed(0)} %
            </small>
          </div>
          <ProgressBar
            value={project.progress?.progress || 0}
            style={{ height: "5px" }}
            showValue={false}
          ></ProgressBar>
        </div>
      </>
    );
  };

  const modulesTestCasesTemplate = (project) => {
    return (
      <>
        <div className="d-flex">
          <span className="badge bg-warning-subtle text-warning-emphasis border border-warning-subtle fw-normal me-2">
            {project.modulesCount} Modules
          </span>
          <span className="badge bg-info-subtle text-info-emphasis border border-info-subtle fw-normal">
            {project.testCasesCount} Test Cases
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(formatTime(new Date()));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getProjects();
    getModules();
    getTestCases();
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Testing Reporting & Control Evaluation" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <h3 className="fw-bold">Dashboard</h3>
              <small>Here's a brief overview of your performance.</small>
            </div>
            <div className="d-flex flex-column text-end">
              <h3 className="fw-bold">{time}</h3>
              <small>{formatDate(new Date())}</small>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-3 mb-3 mb-sm-0">
              <div className="card bg-success-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Projects</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="card-text fw-bold">
                      {projects?.length || 0}
                    </h2>
                    <button
                      className="btn btn-sm btn-success bg-success-subtle text-success-emphasis fw-medium border border-success-subtle"
                      style={{ height: "fit-content" }}
                      onClick={() => navigate("/projects/all")}
                    >
                      View All Projects
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 mb-3 mb-sm-0">
              <div className="card bg-primary-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Modules</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="card-text fw-bold">
                      {modules?.length || 0}
                    </h2>
                    <button
                      className="btn btn-sm btn-primary bg-primary-subtle text-primary-emphasis fw-medium border border-primary-subtle"
                      style={{ height: "fit-content" }}
                      onClick={() => navigate("/modules/all")}
                    >
                      View All Modules
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 mb-3 mb-sm-0">
              <div className="card bg-warning-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Test Cases</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="card-text fw-bold">
                      {testCases?.length || 0}
                    </h2>
                    <button
                      className="btn btn-sm btn-warning bg-warning-subtle text-warning-emphasis fw-medium text-light border border-warning-subtle"
                      style={{ height: "fit-content" }}
                      onClick={() => navigate("/testcases/all")}
                    >
                      View All Test Cases
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 mb-3 mb-sm-0">
              <div className="card bg-danger-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Progress</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="card-text fw-bold">
                      {progress.toFixed(2)}%
                    </h2>
                    <button
                      className="btn btn-sm btn-danger bg-danger-subtle text-danger-emphasis fw-medium text-light border border-danger-subtle"
                      style={{ height: "fit-content" }}
                      onClick={() => navigate("/testcases/all")}
                    >
                      View Progress
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <div className="container-fluid px-0">
              <div className="row my-3">
                <span className="fw-medium fs-5">Active Projects</span>
              </div>
              <div className="row">
                {projects &&
                  projects.map((project) => (
                    <div className="col-12 col-sm-3 mb-4" key={project.id}>
                      <Card
                        key={project.id}
                        className="project-card dark-gradient-bg border h-100"
                      >
                        <h6 className="fw-bold">{project.title}</h6>
                        {modulesTestCasesTemplate(project)}
                        <div className="mt-2">{progressTemplate(project)}</div>
                        <button
                          className="btn btn-sm border border-dark-subtle mt-3"
                          onClick={() =>
                            navigate(`/projects/view/${project.id}`)
                          }
                        >
                          View Project
                        </button>
                      </Card>
                    </div>
                  ))}
                {projectLoading && (
                  <>
                    <ActiveProjectsSkeleton />
                  </>
                )}
                {
                  !projectLoading && projects.length === 0 && (
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <h6 className="fw-bold text-muted">
                          No active projects found.
                        </h6>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default App;
