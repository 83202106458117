import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewModule from "../../components/MasterFiles/NewModule";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import AssignModuleTester from "../../components/MasterFiles/AssignModuleTester";
import { UPLOADS_URL } from "../../config/api";
import { AvatarGroup } from "primereact/avatargroup";
import { Avatar } from "primereact/avatar";
import ViewAssignees from "../../components/MasterFiles/ViewAssignees";

function AllModules() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewModuleModal, setShowNewModuleModal] = useState(false);
  const [showAssignTesterModal, setShowAssignTesterModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [modules, setModules] = useState([]);

  const [selectedProject, setSelectedProject] = useState({
    id: 0,
    title: "All Projects",
  });
  const [selectedModule, setSelectedModule] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  // View Assignees
  const [assingeeDialogHeader, setAssigneeDialogHeader] = useState("");
  const [viewAssigneeDialogVisible, setViewAssigneesDialogVisible] =
    useState(false);
  const [viewAssigneeDialogAssignees, setViewAssigneeDialogAssignees] =
    useState([]);

  const toggleNewModule = () => {
    setShowNewModuleModal(!showNewModuleModal);
  };

  const toggleAssignTesterModal = () => {
    setShowAssignTesterModal(!showAssignTesterModal);
  };

  const showError = (error) => {
    const status = error?.response?.status;
    if (status != 404) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getProjects = async () => {
    try {
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        response.data.unshift({
          id: 0,
          title: "All Projects",
        });
        setProjects(response.data);
      }
    } catch (error) {
      showError(error);
    }
  };

  const getModules = async (_selectedProject) => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get(
        "module/all?projectId=" + _selectedProject?.id || 0
      );
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (error) {
      setModules([]);
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteModule = async (moduleId) => {
    try {
      const response = await protectedAPI.delete("module/delete/" + moduleId);
      if (response.status === 200) {
        getModules();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showError(error);
    }
  };

  const deleteModuleConfirm = (moduleId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this module?",
      onAccept: () => deleteModule(moduleId),
    });
  };

  const openEditModal = (module) => {
    setSelectedModule(module);
    setIsUpdate(true);
    toggleNewModule();
  };

  const openAssignTesterModal = (module) => {
    setSelectedModule(module);
    toggleAssignTesterModal();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const codeTemplate = (module) => {
    return (
      <small
        className="badge text-bg border border-dark-subtle fw-bold"
        style={{ width: "fit-content", fontSize: "11px" }}
      >
        {module.code}
      </small>
    );
  };

  const titleTemplate = (module) => {
    return (
      <>
        <div className="d-flex flex-column">
          <small className="fw-bold">{module.title}</small>
          <small className="text-muted" style={{ width: "fit-content" }}>
            {module.projectTitle}
          </small>
        </div>
      </>
    );
  };

  const moduleTesterTemplate = (module) => {
    if (module) {
      return (
        <>
          <div className="d-flex mb-2">
            <small>
              {module?.moduleTesters && module?.moduleTesters?.length > 0 ? (
                <AvatarGroup>
                  {module.moduleTesters.map(
                    (manager, key) =>
                      key < 2 && (
                        <Avatar
                          key={key}
                          image={
                            UPLOADS_URL +
                            "employees/" +
                            (manager.userDetails.image || "no-user.png")
                          }
                          size="small"
                          shape="circle"
                          onClick={() => {
                            setAssigneeDialogHeader(`Assigned Module Testers`);
                            setViewAssigneeDialogAssignees(
                              module.moduleTesters
                            );
                            setViewAssigneesDialogVisible(true);
                          }}
                        />
                      )
                  )}
                  {module.moduleTesters.length > 2 && (
                    <Avatar
                      label={`+${module.moduleTesters.length - 2}`}
                      shape="circle"
                      size="small"
                      onClick={() => {
                        setAssigneeDialogHeader(`Assigned Module Testers`);
                        setViewAssigneeDialogAssignees(module.moduleTesters);
                        setViewAssigneesDialogVisible(true);
                      }}
                    />
                  )}
                </AvatarGroup>
              ) : module?.userGroup === "Super Admin" ||
                module?.userGroup === "Project Manager" ? (
                <a
                  href="#"
                  className="link-secondary"
                  onClick={() => {
                    openAssignTesterModal(module);
                  }}
                  style={{ fontSize: "12px" }}
                >
                  <i className="pi pi-plus me-2"></i>
                  Assign Tester
                </a>
              ) : (
                <i>No people assigned.</i>
              )}
            </small>
          </div>
        </>
      );
    }
  };

  const progressTemplate = (module) => {
    return (
      <>
        <div className="d-flex flex-column mt-2">
          <div className="d-flex justify-content-between mb-2">
            <small>{module.progress?.progress || 0} %</small>
          </div>
          <ProgressBar
            value={module.progress?.progress || 0}
            style={{ height: "5px" }}
            showValue={false}
          ></ProgressBar>
        </div>
      </>
    );
  };

  const modulesTestCasesTemplate = (module) => {
    return (
      <>
        <div className="d-flex">
          <span className="badge bg-warning-subtle text-warning-emphasis border border-warning-subtle fw-normal">
            {module.progress?.acceptedTestCases || 0} /{" "}
            {module.testCasesCount || 0}
          </span>
        </div>
      </>
    );
  };

  const actionTemplate = (module) => {
    return (
      <>
        <div className="btn-group" role="group">
          {(module?.userGroup === "Super Admin" ||
            module?.userGroup === "Project Manager") && (
            <>
              <button
                className="btn btn-sm bg-transparent border"
                onClick={() => {
                  openAssignTesterModal(module);
                }}
              >
                <i className="pi pi-users"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm bg-transparent border"
                onClick={() => openEditModal(module)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm bg-transparent border text-danger"
                onClick={() => deleteModuleConfirm(module.id)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </>
          )}
        </div>
      </>
    );
  };

  const moduleTableData = {
    fetchData: getModules,
    records: modules,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Project ID", field: "projectId" },
      { header: "Project", field: "projectTitle" },
      { header: "Code", field: "code" },
      { header: "Title", field: "title" },
      { header: "Description", field: "description" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      { header: "_Code", field: "code", style: { display: "none" } },
      { header: "Code", body: codeTemplate },
      { header: "_Title", field: "title", style: { display: "none" } },
      { header: "Title", body: titleTemplate },
      { header: "Module Testers", body: moduleTesterTemplate },
      { header: "Test Cases", body: modulesTestCasesTemplate },
      { header: "Progress", body: progressTemplate },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["code", "title"],
    dataKey: "id",
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);
  }, []);

  useEffect(() => {
    getModules(selectedProject);
  }, [selectedProject]);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Modules" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            {user?.groupId == 2 || user?.groupId == 13 ? (
              <div>
                <Button
                  variant="info"
                  onClick={() => {
                    setSelectedModule(null);
                    setIsUpdate(false);
                    toggleNewModule();
                  }}
                >
                  <i className="pi pi-plus me-1"></i> Create New Module
                </Button>
              </div>
            ) : null}
            <div className="d-flex">
              <div className="d-flex align-items-center me-3">
                <span>
                  <i className="pi pi-filter"></i>
                </span>
              </div>
              <Dropdown
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.value)}
                options={projects}
                filter
                optionLabel="title"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={moduleTableData.records}
                  fetchData={moduleTableData.fetchData}
                  exportColumns={moduleTableData.exportColumns}
                  tableColumns={moduleTableData.tableColumns}
                  globalFilterFields={moduleTableData.globalFilterFields}
                  dataKey={moduleTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewModule
        show={showNewModuleModal}
        toggle={toggleNewModule}
        getModules={getModules}
        isUpdate={isUpdate}
        selectedModule={selectedModule}
      />
      <AssignModuleTester
        show={showAssignTesterModal}
        toggle={toggleAssignTesterModal}
        reloadModules={getModules}
        _selectedModule={selectedModule}
      />
      <ViewAssignees
        header={assingeeDialogHeader}
        viewAssgineeDialogVisible={viewAssigneeDialogVisible}
        setViewAssigneesDialogVisible={setViewAssigneesDialogVisible}
        assignees={viewAssigneeDialogAssignees}
      />
    </div>
  );
}

export default AllModules;
