import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import NewRiskCat from "../../components/MasterFiles/NewRiskCat";

const AllRiskCat = () => {
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewRiskCatModal, setShowNewRiskCatModal] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedRiskCat, setSelectedRiskCat] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewRiskCat = () => {
    setShowNewRiskCatModal(!showNewRiskCatModal);
  };

  const showError = (error) => {
    const status = error?.response?.status;
    if (status != 404) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getRiskCat = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("riskCategory/all");
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRiskCat = async (riskCatId) => {
    try {
      const response = await protectedAPI.delete(
        "riskCategory/delete/" + riskCatId
      );
      if (response.status === 200) {
        getRiskCat();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showError(error);
    }
  };

  const deleteTestCaseConfirm = (riskCatId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this testcase?",
      onAccept: () => deleteRiskCat(riskCatId),
    });
  };

  const openEditModal = (riskCat) => {
    setSelectedRiskCat(riskCat);
    setIsUpdate(true);
    toggleNewRiskCat();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const titleTemplate = (riskCat) => {
    return (
      <>
        <div className="d-flex flex-column">
          <small>{riskCat.title}</small>
        </div>
      </>
    );
  };

  const actionTemplate = (riskCat) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(riskCat)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteTestCaseConfirm(riskCat.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const riskCatTableData = {
    fetchData: getRiskCat,
    records: modules,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Title", field: "title" },
      { header: "Created at", field: "createdAt" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "70px" } },
      { header: "_Title", field: "title", style: { display: "none" } },
      { header: "Title", body: titleTemplate },
      /*  { header: "Severity", body: severityTemplate }, */
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["createdBy", "title"],
    dataKey: "id",
  };

  useEffect(() => {
    getRiskCat();
  }, []);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Risk Category" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            {user?.groupId == 2 || user?.groupId == 3 ? (
              <div>
                <Button
                  variant="info"
                  onClick={() => {
                    setSelectedRiskCat(null);
                    setIsUpdate(false);
                    toggleNewRiskCat();
                  }}
                >
                  <i className="pi pi-plus me-1"></i> Create New Risk Category
                </Button>
              </div>
            ) : null}
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={riskCatTableData.records}
                  fetchData={riskCatTableData.fetchData}
                  exportColumns={riskCatTableData.exportColumns}
                  tableColumns={riskCatTableData.tableColumns}
                  globalFilterFields={riskCatTableData.globalFilterFields}
                  dataKey={riskCatTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewRiskCat
        show={showNewRiskCatModal}
        toggle={toggleNewRiskCat}
        getRiskCat={getRiskCat}
        isUpdate={isUpdate}
        selectedRiskCat={selectedRiskCat}
      />
    </div>
  );
};

export default AllRiskCat;
